/** @format */
import React from "react";
import OwlCarousel from "react-owl-carousel";

export const Slider = () => {
  return (
    <OwlCarousel loop margin={0} items={1} autoPlay autoplayTimeout={5000} dots nav>
      <div
          className="intro-slide slide1"
          style={{
            backgroundImage: "url(/assets/images/slider.jpeg)",
          }}
        >
          <div className="container intro-content text-center">
            <h3 className="intro-subtitle text-white">Relax your body,</h3>
            <h1 className="intro-title text-gold">free your mind.</h1>
            <a href="/services" className="btn btn-primary">
              Discover NOW
            </a>
          </div>
        </div>

        <div
          className="intro-slide"
          style={{
            backgroundImage: "url(/assets/images/oil-3.jpg)",
          }}
        >
          <div className="container intro-content text-center">
            <h3 className="intro-subtitle text-white">Escape the stress,</h3>
            <h1 className="intro-title text-gold">Revitalize your body</h1>
            <a href="/services" className="btn btn-primary">
              Discover NOW
            </a>
          </div>
        </div>

    </OwlCarousel>
  );
};
