/** @format */

export const Services = () => {
  return (

    <div
      className="bg-lighter pt-7 pb-4"
      style={{ backgroundColor: "#fafafa" }}
    >
      {/* Restore previous images */}
      <div className="container">
        <div className="instagram-feed-container">
          <div className="row">
            <div className="feed-col">
              <div className="instagram-feed">
                <img
                  src="https://media.tacdn.com/media/attractions-splice-spp-360x240/0e/c1/34/54.jpg"
                  alt="img"
                />

                <div className="instagram-feed-content">
                  <a href="#/">
                    <i className="icon-heart-o"></i>387
                  </a>
                  <a href="#/">
                    <i className="icon-comments"></i>45
                  </a>
                </div>
              </div>
            </div>
            <div className="feed-col">
              <div className="instagram-feed">
                <img
                  src="https://pctechmag.com/wp-content/uploads/2019/07/inDriver-Hailing-service.png"
                  alt="img"
                />

                <div className="instagram-feed-content">
                  <a href="#/">
                    <i className="icon-heart-o"></i>387
                  </a>
                  <a href="#/">
                    <i className="icon-comments"></i>45
                  </a>
                </div>
              </div>
            </div>
            <div className="feed-col feed-col-title">
              <div className="instagram-feed-title">
                <i className="icon-instagram"></i>
                <p>
                  Sunflower <br />
                  Transporation Services
                </p>
                {/* <a href="#/">FOLLOW</a> */}
              </div>
            </div>
            <div className="feed-col">
              <div className="instagram-feed">
                <img
                  src="https://www.passportandpixels.com/wp-content/uploads/2020/03/Ngorongoro-60_pp.jpg"
                  alt="img"
                />

                <div className="instagram-feed-content">
                  <a href="#/">
                    <i className="icon-heart-o"></i>387
                  </a>
                  <a href="#/">
                    <i className="icon-comments"></i>45
                  </a>
                </div>
              </div>
            </div>
            <div className="feed-col">
              <div className="instagram-feed">
                <img
                  src="https://www.lifetimesafaris.com/Images/Car_Hire_Kampala_Uganda/Udanda_safari_van%20_1.JPG"
                  alt="img"
                />

                <div className="instagram-feed-content">
                  <a href="#/">
                    <i className="icon-heart-o"></i>387
                  </a>
                  <a href="#/">
                    <i className="icon-comments"></i>45
                  </a>
                </div>
              </div>
            </div>
            <div className="feed-col">
              <div className="instagram-feed">
                <img
                  src="https://rentacaruganda.com/wordpress/wp-content/uploads/2016/03/Tourist-Van.jpg"
                  alt="Sunfower Tourist Van Hire"
                />

                <div className="instagram-feed-content">
                  <a href="#/">
                    <i className="icon-heart-o"></i>387
                  </a>
                  <a href="#/">
                    <i className="icon-comments"></i>45
                  </a>
                </div>
              </div>
            </div>
            <div className="feed-col">
              <div className="instagram-feed">
                <img
                  src="https://flightitineraryforvisa.com/wp-content/uploads/2018/12/Hotel-Booking-1280x720.jpg"
                  alt="Sunflower Hotel Booking Services"
                />

                <div className="instagram-feed-content">
                  <a href="#/">
                    <i className="icon-heart-o"></i>387
                  </a>
                  <a href="#/">
                    <i className="icon-comments"></i>45
                  </a>
                </div>
              </div>
            </div>
            <div className="feed-col">
              <div className="instagram-feed">
                <img
                  src="https://fleet53.com/wp-content/uploads/2020/12/Office-Movers.jpg"
                  alt="Sunflower Office Moving Services"
                />

                <div className="instagram-feed-content">
                  <a href="#/">
                    <i className="icon-heart-o"></i>387
                  </a>
                  <a href="#/">
                    <i className="icon-comments"></i>45
                  </a>
                </div>
              </div>
            </div>
            <div className="feed-col">
              <div className="instagram-feed">
                <img
                  src="https://www.cmremovals.co.za/Portals/50/Move-with-peace-of-mind.jpg?ver=2018-01-17-183707-447"
                  alt="Sunflower Office Movers Service"
                />

                <div className="instagram-feed-content">
                  <a href="#/">
                    <i className="icon-heart-o"></i>387
                  </a>
                  <a href="#/">
                    <i className="icon-comments"></i>45
                  </a>
                </div>
              </div>
            </div>
            <div className="feed-col">
              <div className="instagram-feed">
                <img
                  src="https://mrcheaptransport.co.za/wp-content/uploads/2017/09/Cheapest-Long-Distance-Furniture-Removals-3.jpg"
                  alt="Sunflower Office Movers"
                />

                <div className="instagram-feed-content">
                  <a href="#/">
                    <i className="icon-heart-o"></i>387
                  </a>
                  <a href="#/">
                    <i className="icon-comments"></i>45
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};
