/** @format */

import { ContactusForm } from "../../components/contactus/ContactusForm";
import { Header } from "../../components/Header/Header";

export const ContactUs = () => {
  return (
    <div className="page-wrapper">
      <Header
        title="header-contactus"
        imgUrl="assets/images/sunflowerlogo.png"
        page="Contact"
      />
      <main className="main">
        <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Pages</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Contact us
              </li>
            </ol>
          </div>
        </nav>
        <div className="container">
          <div
            className="page-header page-header-big text-center"
            style={{
              backgroundImage: "url('assets/images/real/spa4.jpg')",
            }}
          >
            <h1 className="page-title text-white">
              Contact us
              <span className="text-white">keep in touch with us</span>
            </h1>
          </div>
        </div>

        <div className="page-content pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h2 className="title mb-1">Contact Information</h2>
                <p className="mb-3">
                  We would love to hear from you! Whether you have a question,
                  feedback, or would like to book an appointment, our friendly
                  and knowledgeable staff are ready to assist you. You can reach
                  us by phone, email, or by filling out our online contact form.
                  We look forward to hearing from you!
                </p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      <h3>The Office</h3>

                      <ul className="contact-list">
                        <li>
                          <i className="icon-map-marker"></i>
                          Bukoto Kiwana Road Opposite total Petrol Station
                        </li>
                        <li>
                          <i className="icon-phone"></i>
                          <a href="tel:+256709711783">+256 709 711783</a>
                        </li>
                        <li>
                          <i className="icon-phone"></i>
                          <a href="tel:+256709711783">+256 780 643099</a>
                        </li>
                        <li>
                          <i className="icon-envelope"></i>
                          <a href="mailto:sunflowermassagespa840@gmail.com">sunflowermassagespa840@gmail.com</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-sm-5">
                    <div className="contact-info">
                      <h3>The Office</h3>

                      <ul className="contact-list">
                        <li>
                          <i className="icon-clock-o"></i>
                          <span className="text-dark">
                            Monday-Saturday
                          </span>
                          <br />
                          24 HRS
                        </li>
                        <li>
                          <i className="icon-calendar"></i>
                          <span className="text-dark">Sunday</span> <br />
                          24 HRS
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h2 className="title mb-1">Got Any Questions?</h2>
                <p className="mb-2">
                  Use the form below to get in touch with the Customer Support
                </p>
               <ContactusForm />
              </div>
            </div>
            <hr className="mt-4 mb-5" />
          </div>
          <div id="map">
          <iframe title="map" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=SUN FLOWER MASSAGE SPA&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="600" height="450"  loading="lazy" style={{width: '100%', height: '100%', border: 'none'}}></iframe>
          </div>
        </div>
      </main>
    </div>
  );
};
