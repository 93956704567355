/** @format */

import { Header } from "../../components/Header/Header";

export const AboutUs = () => {
  return (
    <div className="page-wrapper">
      <Header
        title="header-aboutus"
        imgUrl="/assets/images/sunflowerlogo.png"
        page="About"
      />
      <main className="main">
        <div
          className="page-header text-center"
          style={{
            backgroundImage: "url('/assets/images/real/spa7.jpg')",
          }}
        >
          <div className="container">
            <h1 className="page-title text-white">
              About us<span>Sunflower Spa</span>
            </h1>
          </div>
        </div>
        <div className="page-content pb-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="about-text text-center mt-3">
                  <h2 className="title text-center mb-2">Who We Are</h2>
                  <p>
                    At Sunflower massage parlour, we are dedicated to providing
                    our clients with a rejuvenating and relaxing experience. Our
                    highly skilled and experienced massage therapists are
                    passionate about their craft and are committed to delivering
                    the highest level of service to each and every client. We
                    believe that massage therapy can be a powerful tool in
                    promoting physical and mental wellness, and we strive to
                    create an environment that encourages relaxation, healing,
                    and tranquility.{" "}
                  </p>
                  <img
                    src="assets/images/about/about-2/signature.png"
                    alt="signature"
                    className="mx-auto mb-5"
                  />

                  <img
                    src="/assets/images/real/spa13.jpg"
                    alt="bg"
                    className="mx-auto mb-6"
                  />
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-4 col-sm-6">
                <div className="icon-box icon-box-sm text-center">
                  <span className="icon-box-icon">
                    <i className="icon-puzzle-piece"></i>
                  </span>
                  <div className="icon-box-content">
                    <h3 className="icon-box-title">Great Environment</h3>
                    <p>
                    Our massage parlour boasts a serene and welcoming environment, designed to promote relaxation and a sense of calmness <br />
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <div className="icon-box icon-box-sm text-center">
                  <span className="icon-box-icon">
                    <i className="icon-life-ring"></i>
                  </span>
                  <div className="icon-box-content">
                    <h3 className="icon-box-title">Professional Staff</h3>
                    <p>
                    Our massage therapists are highly trained and experienced professionals, committed to providing exceptional service and personalized attention to each client..{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <div className="icon-box icon-box-sm text-center">
                  <span className="icon-box-icon">
                    <i className="icon-heart-o"></i>
                  </span>
                  <div className="icon-box-content">
                    <h3 className="icon-box-title">Value for Money</h3>
                    <p>
                    We offer competitive pricing and packages, ensuring that our clients receive great value for their money without compromising on quality.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
