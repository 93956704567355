import { Outlet } from "react-router-dom";
import { Footer } from "../components/Footer/Footer";
import { MobileMenu } from "../components/MobileMenu/MobileMenu";

const MainLayout = () => {
  return (
    <>
      <Outlet />
      <Footer />
      <button id="scroll-top" title="Back to Top"><i className="icon-arrow-up"></i></button>
      <MobileMenu />
    </>
  );
};

export default MainLayout;
