import React from "react";

interface Props {
  title: string;
  imgUrl: string;
  page: string;
}
export const Header: React.FC<Props> = ({title, imgUrl, page}) => {
  const path = window.location.pathname;

  return (
    <header className={`header ${title}`}>
      <div className="header-middle sticky-header">
        <div className="container-fluid">
          <div className="header-left">
            <button className="mobile-menu-toggler">
              <span className="sr-only">Toggle mobile menu</span>
              <i className="icon-bars"></i>
            </button>

            <a href="index.html" className="logo">
              <img
                src={imgUrl}
                alt="Molla Logo"
                width="105"
                height="25"
              />
            </a>
          </div>
          <div className="header-center">
          <nav className="main container">
              <ul className="menu">
                <li className={`megamenu-container ${path === "/"? "active": null}`}>
                  <a href="/">Home</a>
                </li>
                <li className={`${path === "/services"? "active": null}`}>
                  <a href="/services">Services</a>
                </li>
                <li className={`${path === '/aboutus'? "active": null}`}>
                  <a href="/aboutus">About Us</a>
                </li>

                <li className={`${path === '/contactus' ? "active": null }`}>
                  <a href="/contactus">Contact Us</a>
                </li>
              </ul>
            </nav>

          </div>
        </div>
      </div>
    </header>
  );
};
