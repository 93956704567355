/** @format */

export const InstagramFeed = () => {
  return (
    <div
      className="bg-lighter pt-7 pb-4"
      style={{ backgroundColor: "#fafafa" }}
    >
        {/* Restore previous images */}
      <div className="container">
        <div className="instagram-feed-container">
          <div className="row">
            <div className="feed-col">
              <div className="instagram-feed">
                <img
                  src="assets/images/service1.jpeg"
                  alt="img"
                />

                <div className="instagram-feed-content">
                  <a href="#/">
                    <i className="icon-heart-o"></i>387
                  </a>
                  <a href="#/">
                    <i className="icon-comments"></i>45
                  </a>
                </div>
              </div>
            </div>
            <div className="feed-col">
              <div className="instagram-feed">
                <img
                  src="assets/images/oil-2.jpeg"
                  alt="img"
                />

                <div className="instagram-feed-content">
                  <a href="#/">
                    <i className="icon-heart-o"></i>387
                  </a>
                  <a href="#/">
                    <i className="icon-comments"></i>45
                  </a>
                </div>
              </div>
            </div>
            <div className="feed-col feed-col-title">
              <div className="instagram-feed-title">
                <i className="icon-instagram"></i>
                <p>
                  @sunflowerspa <br />
                  on instagram
                </p>
                <a href="#/">FOLLOW</a>
              </div>
            </div>
            <div className="feed-col">
              <div className="instagram-feed">
                <img
                  src="assets/images/service2.jpeg"
                  alt="img"
                />

                <div className="instagram-feed-content">
                  <a href="#/">
                    <i className="icon-heart-o"></i>387
                  </a>
                  <a href="#/">
                    <i className="icon-comments"></i>45
                  </a>
                </div>
              </div>
            </div>
            <div className="feed-col">
              <div className="instagram-feed">
                <img
                  src="/assets/images/service3.jpeg"
                  alt="img"
                />

                <div className="instagram-feed-content">
                  <a href="#/">
                    <i className="icon-heart-o"></i>387
                  </a>
                  <a href="#/">
                    <i className="icon-comments"></i>45
                  </a>
                </div>
              </div>
            </div>
            <div className="feed-col">
              <div className="instagram-feed">
                <img
                  src="/assets/images/demos/demo-5/instagram/insta5.jpg"
                  alt="img"
                />

                <div className="instagram-feed-content">
                  <a href="#/">
                    <i className="icon-heart-o"></i>387
                  </a>
                  <a href="#/">
                    <i className="icon-comments"></i>45
                  </a>
                </div>
              </div>
            </div>
            <div className="feed-col">
              <div className="instagram-feed">
                <img
                  src="assets/images/demos/demo-5/instagram/insta6.jpg"
                  alt="img"
                />

                <div className="instagram-feed-content">
                  <a href="#/">
                    <i className="icon-heart-o"></i>387
                  </a>
                  <a href="#/">
                    <i className="icon-comments"></i>45
                  </a>
                </div>
              </div>
            </div>
            <div className="feed-col">
              <div className="instagram-feed">
                <img
                  src="assets/images/demos/demo-5/instagram/insta7.jpg"
                  alt="img"
                />

                <div className="instagram-feed-content">
                  <a href="#/">
                    <i className="icon-heart-o"></i>387
                  </a>
                  <a href="#/">
                    <i className="icon-comments"></i>45
                  </a>
                </div>
              </div>
            </div>
            <div className="feed-col">
              <div className="instagram-feed">
                <img
                  src="assets/images/oil.jpeg"
                  alt="img"
                />

                <div className="instagram-feed-content">
                  <a href="#/">
                    <i className="icon-heart-o"></i>387
                  </a>
                  <a href="#/">
                    <i className="icon-comments"></i>45
                  </a>
                </div>
              </div>
            </div>
            <div className="feed-col">
              <div className="instagram-feed">
                <img
                  src="assets/images/demos/demo-5/instagram/insta9.jpg"
                  alt="img"
                />

                <div className="instagram-feed-content">
                  <a href="#/">
                    <i className="icon-heart-o"></i>387
                  </a>
                  <a href="#/">
                    <i className="icon-comments"></i>45
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-sm-6 col-lg-4">
            <div className="icon-box icon-box-side">
              <span className="icon-box-icon text-dark">
                <i className="icon-rocket"></i>
              </span>

              <div className="icon-box-content">
                <h3 className="icon-box-title">Transportation Services</h3>
                <p>100 % best service deliveries</p>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className="icon-box icon-box-side">
              <span className="icon-box-icon text-dark">
                <i className="icon-refresh"></i>
              </span>

              <div className="icon-box-content">
                <h3 className="icon-box-title">Safari Trips</h3>
                <p>We offer designated drivers to your Safari Trips and car hires</p>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className="icon-box icon-box-side">
              <span className="icon-box-icon text-dark">
                <i className="icon-life-ring"></i>
              </span>

              <div className="icon-box-content">
                <h3 className="icon-box-title">Hotel Bookings</h3>
                <p>We also provide smooth booking services for Hotels at the best rates</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// https://media.tacdn.com/media/attractions-splice-spp-360x240/0e/c1/34/54.jpg
// https://pctechmag.com/wp-content/uploads/2019/07/inDriver-Hailing-service.png