import { gql } from '@apollo/client';

export const FETCH_SERVICES = gql`
    query getServices {
        Services {
        id
        name
        description
        }
    }
`;
