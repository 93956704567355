export const MobileMenu = () => {
    return (
        <>
        <div className="mobile-menu-overlay"></div>
        <div className="mobile-menu-container mobile-menu-light">
        <div className="mobile-menu-wrapper">
            <span className="mobile-menu-close"><i className="icon-close"></i></span>
            <nav className="mobile-nav">
                <ul className="mobile-menu">
                    <li className="active">
                        <a href="/">Home</a>
                    </li>
                    <li>
                        <a href="/services">Services</a>
                    </li>
                    <li>
                        <a href="/aboutus" className="sf-with-ul">About Us</a>
                    </li>
                    <li>
                        <a href="/contactus" className="sf-with-ul">Contact Us</a>
                    </li>
                    
                </ul>
            </nav>

            <div className="social-icons">
                <a href="https://www.facebook.com/profile.php?id=100067891853638&mibextid=ZbWKwL" className="social-icon" target="_blank" rel="noreferrer" title="Facebook"><i className="icon-facebook-f"></i></a>
                <a href="https://www.twitter.com/sunflowerMspa" className="social-icon" target="_blank" rel="noreferrer" title="Twitter"><i className="icon-twitter"></i></a>
                <a href="#/" className="social-icon" target="_blank" title="Instagram"><i className="icon-instagram"></i></a>
            </div>
        </div>
    </div>
        </>
    )
}