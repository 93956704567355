import { Helmet } from 'react-helmet';

import { Banner } from "../../components/Banner/Banner";
import { Header } from "../../components/Header/Header";
import { InstagramFeed } from "../../components/InstagramFeed/InstagramFeed";
import { Slider } from "../../components/Slider/Slider";
import { Services } from '../../components/Services/Services';

export const Home = () => {
  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Sunflower Palour - Your Relaxing Zone</title>
      </Helmet>
      <Header title="header-5" imgUrl="/assets/images/sunflowerlogo.png" page="Home" />
      <main className="main">
        <Slider/>
        <Banner />
        <InstagramFeed />
        <Services />
      </main>
    </div>
  );
};
