export const Footer = () => {
    return(
        <footer className="footer footer-2">
            <div className="footer-middle border-0">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-lg-6">
                            <div className="widget widget-about">
                                <img src="assets/images/sunflowerlogo.png" className="footer-logo" alt="Footer Logo" width="205" height="50" />
                                <p> 
                                Indulge in the ultimate indulgence of relaxation and rejuvenation, as our expert massage therapists transport you to a tranquil oasis of pure bliss, where you can escape the stress of everyday life and experience
                                 the healing power of touch in a personalized, peaceful, and luxurious environment.
                                </p>
                                
                                <div className="widget-about-info">
                                    <div className="row">
                                        <div className="col-sm-6 col-md-4">
                                            <span className="widget-about-title">Got Question? Call us 24/7</span>
                                            <a href="tel:123456789">+256 709 711783 / +256 780 643099</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-4 col-lg-2">
                            <div className="widget">
                                <h4 className="widget-title">Information</h4>

                                <ul className="widget-list">
                                    <li><a href="/aboutus">About Sunflower</a></li>
                                    <li><a href="/contactus">Contact us</a></li>
                                    <li><a href="/services">Services</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-sm-4 col-lg-2">
                            <div className="widget">
                                <h4 className="widget-title">Customer Service</h4>

                                <ul className="widget-list">
                                    <li><a href="#/">Terms and conditions</a></li>
                                    <li><a href="#/">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <div className="container">
                    <p className="footer-copyright">Copyright © 2023 Sunflower Spa. All Rights Reserved.</p>
                    <ul className="footer-menu">
                    </ul>
                    <div className="social-icons social-icons-color">
                        <span className="social-label">Social Media</span>
                        <a href="https://www.facebook.com/profile.php?id=100067891853638&mibextid=ZbWKwL" className="social-icon social-facebook" title="Facebook" rel="noreferrer" target="_blank"><i className="icon-facebook-f"></i></a>
                        <a href="https://www.twitter.com/sunflowerMspa" className="social-icon social-twitter" title="Twitter" target="_blank" rel="noreferrer"><i className="icon-twitter"></i></a>
                        <a href="#/" className="social-icon social-instagram" title="Instagram" target="_blank"><i className="icon-instagram"></i></a>
                    </div>
                </div>
            </div>
        </footer>
    )
}