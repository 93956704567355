import { useRoutes }  from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import { AboutUs } from './pages/Aboutus/AboutUs';
import { ContactUs } from './pages/Contactus/ContactUs';
import { Home } from './pages/Home/Home';
import { Services } from './pages/Services/Services';

const Router = () => useRoutes([
    {
       element: <MainLayout />,
       children: [
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/aboutus',
            element: <AboutUs />
        },
        {
            path: '/services',
            element: <Services />
        },
        {
            path: '/contactus',
            element: <ContactUs />
        }
       ]

    }
   
]);

export default Router;
