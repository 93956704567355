
export const Banner = () => {
    return (
        <div className="container mt-4">
                <div className="banner-group">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="banner banner-border-hover">
                                <a href="#/">
                                    <img src="/assets/images/demos/demo-5/banners/massage.jpg" alt="Banner" />
                                </a>

                                <div className="banner-content">
                                    <h3 className="banner-title"><a href="#/"><span>Swedish<br />Massage</span></a></h3>
                                    <a href="/services" className="btn btn-outline-primary-2 banner-link">Discover Now<i className="icon-long-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="banner banner-border">
                                <a href="#/">
                                    <img src="/assets/images/demos/demo-5/banners/massage2.jpg" alt="Banner" />
                                </a>

                                <div className="banner-content">
                                    <h3 className="banner-title"><a href="#/"><span>Hot stone <br/>Massage</span></a></h3>
                                    <a href="/services" className="btn btn-outline-primary-2 banner-link">Discover Now<i className="icon-long-arrow-right"></i></a>
                                </div>
                            </div>

                            <div className="banner banner-border">
                                <a href="#/">
                                    <img src="/assets/images/demos/demo-5/banners/massage3.jpg" alt="Banner" />
                                </a>

                                <div className="banner-content">
                                    <h3 className="banner-title"><a href="#/"><span>Deep tissue <br/>massage</span></a></h3>
                                    <a href="/services" className="btn btn-outline-primary-2 banner-link">Discover Now<i className="icon-long-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}