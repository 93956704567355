import { useForm, ValidationError } from "@formspree/react";
import { useEffect, useState } from "react";

const SuccessAlert = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  if (visible) {
    return (
      <div className="alert alert-success mb-2" role="alert">
        Form submitted successfully
      </div>
    );
  } else {
    return null;
  }
};

export const ContactusForm = () => {
  const [state, handleSubmit] = useForm("mnqyloqj");
  const [formState, setFormState] = useState({
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });
  const handleFormChange = (event: { target: { name: any; value: any } }) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const submitForm = async (data: any) => {
    await handleSubmit(data);
    setFormState({
      name: "",
      phone: "",
      email: "",
      subject: "",
      message: "",
    });
  };
  return (
    <form onSubmit={submitForm} className="contact-form mb-3">
      {state.succeeded && <SuccessAlert />}
      <div className="row">
        <div className="col-sm-6">
          <label htmlFor="cname" className="sr-only">
            Name
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder="Name *"
            value={formState.name}
            onChange={handleFormChange}
            required
          />
          <ValidationError prefix="Name" field="name" errors={state.errors} />
        </div>

        <div className="col-sm-6">
          <label htmlFor="cemail" className="sr-only">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder="Email *"
            value={formState.email}
            onChange={handleFormChange}
            required
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6">
          <label htmlFor="cphone" className="sr-only">
            Phone
          </label>
          <input
            type="tel"
            className="form-control"
            id="phone"
            name="phone"
            value={formState.phone}
            onChange={handleFormChange}
            placeholder="Phone"
          />
          <ValidationError prefix="Phone" field="phone" errors={state.errors} />
        </div>

        <div className="col-sm-6">
          <label htmlFor="csubject" className="sr-only">
            Subject
          </label>
          <input
            type="text"
            className="form-control"
            name="subject"
            id="subject"
            placeholder="Subject"
            value={formState.subject}
            onChange={handleFormChange}
          />
          <ValidationError
            prefix="Subject"
            field="subject"
            errors={state.errors}
          />
        </div>
      </div>

      <label htmlFor="cmessage" className="sr-only">
        Message
      </label>
      <textarea
        className="form-control"
        cols={30}
        rows={4}
        name="message"
        id="message"
        required
        value={formState.message}
        onChange={handleFormChange}
        placeholder="Message *"
      />
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      {!state.submitting && (
        <button
          type="submit"
          className="btn btn-outline-primary-2 btn-minwidth-sm"
        >
          <span>SUBMIT</span>
          <i className="icon-long-arrow-right"></i>
        </button>
      )}
      {state.submitting && (
        <button
          type="submit"
          className="btn btn-outline-primary-2 btn-minwidth-sm"
          disabled={state.submitting}
        >
          <span>SUBMITTING</span>
          <i className="icon-long-arrow-right"></i>
        </button>
      )}
    </form>
  );
};
