/** @format */
import { useQuery } from '@apollo/client';
import { Header } from "../../components/Header/Header";
import { FETCH_SERVICES } from '../../graphql';
import { useEffect } from 'react';

interface Service {
  id: number;
  name: string;
  description: string;
}

export const Services = () => {
  const { loading, data, error } = useQuery(FETCH_SERVICES);

  useEffect(() => {
    if (loading) {
      console.info('loading data...');
    }
    if (data) {
      console.info('Data has been fetched successfully \n', data);
    }
    if (error) {
      console.error(error.message);
    }
  }, [loading, data, error]);

  return (
    <div className="page-wrapper">
      <Header
        title="header-services"
        imgUrl="/assets/images/sunflowerlogo.png"
        page="Services"
      />
      <main className="main">
        <div
          className="page-header text-center"
          style={{
            backgroundImage: "url('/assets/images/real/spa7.jpg')",
          }}
        >
          <div className="container">
            <h1 className="page-title text-white">
              Our Services<span>Sunflower Spa</span>
            </h1>
          </div>
        </div>
        <div className="page-content pb-3">
          <div className="container">
            <h2 className="title mt-4 text-center">Our Services</h2>
            <div className="row justify-content-center mt-5">

              {
                loading && (
                  <div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-secondary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-success" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-danger" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-warning" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-info" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-dark" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )
              }
              {
                data && (
                  data.Services.map((service: Service) => (
                    <div className="col-lg-4 col-sm-6" key={service.id}>
                      <div className="icon-box icon-box-circle text-center">
                        <span className="icon-box-icon">
                          <i className="icon-star-o"></i>
                        </span>
                        <div className="icon-box-content">
                          <h3 className="icon-box-title">{service.name}</h3>
                          <p>
                            {service.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                )
              }
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
